import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo } from "react";

import colours from "../../../styles/colours";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  ellipsisPopoverHeading: {
    padding: "0.25rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    lineHeight: 1,
  },
  ellipsisPopoverBack: {
    border: "none",
    borderBottom: "1px #DDD solid",
    padding: "0.1rem",
  },
  ellipsisPopoverWithBottomPadding: {
    marginBottom: "1rem",
  },
  backArrow: {
    display: "flex",
    flexDirection: "row",
    color: colours.midGrey,
    fontSize: ".7rem",
    cursor: "pointer",
    padding: "0.5rem",
    width: "100%",
  },
  ellipsisContentTitle: {
    ...gStyles.fontSemiBold,
    maxWidth: "8rem",
    paddingLeft: "0.4rem",
  },
};

const EllipsisMenuBackItem = (props) => {
  const { onBack, padBottom } = props;
  const { styles } = useStyles(baseStyles, props);

  return (
    <button
      className={css(
        styles.ellipsisPopoverHeading,
        styles.ellipsisPopoverBack,
        padBottom && styles.ellipsisPopoverWithBottomPadding
      )}
      onClick={onBack}
    >
      <span className={css(styles.backArrow)}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <div className={css(styles.ellipsisContentTitle)}>Back</div>
      </span>
    </button>
  );
};

EllipsisMenuBackItem.propTypes = {
  onBack: PropTypes.func.isRequired,
  padBottom: PropTypes.bool,
};

EllipsisMenuBackItem.defaultProps = {
  padBottom: false,
};

export default memo(EllipsisMenuBackItem);
