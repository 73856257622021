import { faEllipsisH } from "@fortawesome/free-solid-svg-icons/faEllipsisH";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";

import ButtonWithPopout from "components/Buttons/ButtonWithPopout";

import gStyles from "../../styles/GenericStyles";

import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import getHoverQuery from "styles/getHoverQuery";

const baseStyles = {
  ellipsisButton: {
    position: "relative",
    display: "block",
  },
  ellipsisButtonInner: {
    ...gStyles.noButton,
    color: colours.bodyText,
    border: "1px #DADADA solid",
    borderRadius: 4,
    cursor: "pointer",
    pointerEvents: "auto",
    display: "block",
    padding: ".2rem .5rem",
    backgroundColor: "#FFF",
    ...getHoverQuery({
      backgroundColor: "#f7f7f7",
    }),
    ":focus": {
      backgroundColor: "#f7f7f7",
    },
  },
};

const EllipsisButton = (props) => {
  const {
    renderContent,
    positionFixed,
    popoutProps,
    blockToggleOnContentClick,
    hideArrow,
    onClick,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const handleClick = useCallback(
    (toggleMenu, isOpen) => (e) => {
      e.preventDefault();
      e.stopPropagation();
      toggleMenu();
      onClick(!isOpen);
    },
    [onClick]
  );

  const renderInnerContent = useCallback(
    (downshiftProps) => (
      <span
        onClick={!blockToggleOnContentClick ? downshiftProps.toggleMenu : null}
        {...downshiftProps.getMenuProps()}
      >
        {renderContent(downshiftProps)}
      </span>
    ),
    [blockToggleOnContentClick, renderContent]
  );

  const renderButton = useCallback(
    (downshiftProps) => {
      const { toggleMenu, ref, getToggleButtonProps, isOpen } = downshiftProps;

      return (
        <button
          {...getToggleButtonProps()}
          aria-label="Show More Button"
          className={css(styles.ellipsisButtonInner)}
          ref={ref}
          onClick={handleClick(toggleMenu, isOpen)}
          onKeyDown={(e) => {
            eventIsFieldTrigger(e) && e.preventDefault();
            eventIsFieldTrigger(e) && handleClick(toggleMenu)(e);
          }}
        >
          <FontAwesomeIcon icon={faEllipsisH} dataid="ellipsis" />
        </button>
      );
    },
    [handleClick, styles.ellipsisButtonInner]
  );

  return (
    <ButtonWithPopout
      className={css(styles.ellipsisButton)}
      renderButton={renderButton}
      renderContent={renderInnerContent}
      positionFixed={positionFixed}
      offsetArrow
      ariaLabel="Show additional menu"
      hideArrow={hideArrow}
      {...popoutProps}
    />
  );
};

EllipsisButton.propTypes = {
  renderContent: PropTypes.func.isRequired,
  positionFixed: PropTypes.bool,
  popoutProps: PropTypes.object,
  blockToggleOnContentClick: PropTypes.bool,
  hideArrow: PropTypes.bool,
  onClick: PropTypes.func,
};
EllipsisButton.defaultProps = {
  positionFixed: false,
  popoutProps: {},
  blockToggleOnContentClick: false,
  hideArrow: false,
};

export default memo(EllipsisButton);
